import React from "react";

import "./DroneDeliverySystem.scss";

function DroneDeliverySystem() {
    return (
        <div className="drone-delivery-wrapper">
            Hello, this is my drone delivery system!
        </div>
    );
}

export default DroneDeliverySystem;
